.payments-container {
  display: flex;
  justify-content: space-between;
  padding: 1% 0;
}

.payments-column {
  flex: 1;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2%;
}

.toggle-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.payment_logo {
  width: 50%;
}

.payment-containerplan {
  height: 750px; /* or any appropriate value that fits the maximum content size */
}

@media (max-width: 768px) {
  .payments-container {
    flex-direction: column;
    align-items: center;
  }

  .payments-column {
    width: 100%;
    margin: 10px 0;
  }
}
@media (max-width: 700px) {
  .payments-container {
    flex-direction: column;
    align-items: center;
  }

  .payments-column {
    width: 100%;
    margin: 10px 0;
  }
}
