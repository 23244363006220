.footer-bottom {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  color: #27b3df;
}

.policy-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.policy {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1%;
  width: 30%;
}

.footer-bottom hr {
  margin-top: 0;
  border: none;
  border-top: 1px solid currentColor;
}

.policy a,
.policy a:hover,
.policy a:focus {
  color: #27b3df;
  text-decoration: none; /* Optional: to remove the underline from links */
}
.policy a:hover {
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .policy {
    width: 60%;
  }
}

/* Example: If viewport width is 768px or less */
@media (max-width: 768px) {
  .footer-bottom {
    font-size: 0.9em; /* Adjust the value based on your needs */
  }
}

/* Example: If viewport width is 576px or less */
@media (max-width: 576px) {
  .policy {
    width: 80%;
  }
  .footer-bottom {
    font-size: 0.8em; /* Adjust the value based on your needs */
  }
}

@media (max-width: 375px) {
  .policy {
    width: 100%;
  }
  .footer-bottom {
    font-size: 0.8em; /* Adjust the value based on your needs */
  }
}
