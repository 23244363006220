.choose-container {
  padding: 30px;
  text-align: center;

  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.choose-title {
  color: rgb(224, 63, 109);
  font-size: 46px;
  margin-bottom: 25px;
  font-weight: 600;
}

.choose-description,
.choose-footer {
  font-size: 20px;
  color: rgb(255, 255, 255);
  margin: 20px 0;
}

.choose-details {
  margin: 40px 0;
}

.choose-subtitle {
  color: #e74c3c;
  font-size: 29px;
}

.choose-list {
  list-style-type: none;
  padding: 0;
}

.choose-list li {
  margin-bottom: 15px;
  font-size: 25px;
}

@media (max-width: 1024px) {
  .choose-title,
  .yearly-title {
    font-size: 36px;
    margin-bottom: 20px;
  }

  .choose-description,
  .choose-footer,
  .yearly-description,
  .yearly-footer {
    font-size: 18px;
    margin: 15px 0;
  }

  .choose-subtitle,
  .yearly-subtitle {
    font-size: 24px;
  }

  .choose-list li,
  .yearly-list li {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
