.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
}

.logo-style {
  width: 46%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1200px;
}

.logo-container,
.menu-container {
  display: flex;
  align-items: center;
}

.p-hover-color:hover {
  color: #de416c;
  cursor: pointer;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;

  position: relative;
  color: aliceblue;
}

.menu-container {
  display: none;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.menu-container.open {
  display: flex;
  opacity: 1;
  transform: translateY(0);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: slideDown 0.5s ease-in-out forwards; /* Ensure animation completes and holds its last keyframe */
}

.close-button {
  position: fixed;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: aliceblue;
  z-index: 1002;
}

.hidden {
  display: none;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .logo-style {
    width: 65%;
  }
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }
  .logo-style {
    width: 40%;
  }
}

@media (min-width: 769px) {
  .menu-container {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 616px) {
  .logo-style {
    width: 60%;
  }
}

@media (max-width: 484px) {
  .logo-style {
    width: 80%;
  }
}

@media (max-width: 375px) {
  .header-content {
    align-items: center;
    justify-content: space-around;
    text-align: center;
  }
}
