.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Ensure the footer takes the full width */
}

.footer-logo {
  align-items: center;
  display: flex;
  justify-content: center;
}

.logo-img {
  height: 220px;
}

.footer-links {
  display: flex;
  justify-content: space-around;
  width: 30%; /* Adjusted width */
  margin-top: 20px;
}

.footer-links p {
  margin: 0 10px; /* Adjusted margin */
  font-size: 20px;
}

.footer-text {
  width: 90%; /* Adjusted width */
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
}

.footer-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  width: 30%; /* Adjusted width */
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .footer-links {
    display: flex;
    justify-content: space-around;
    width: 50%; /* Adjusted width */
    margin-top: 20px;
  }

  .footer-links p {
    margin: 10px 0;
  }

  .footer-text {
    width: 90%;
  }
  .footer-buttons {
    width: 80%; /* Adjusted width */
  }
}

@media (max-width: 768px) {
  .footer-text {
    width: 95%;
    font-size: 18px;
  }

  .logo-img {
    height: 90px;
  }
  .footer-buttons {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .footer-links {
    flex-direction: column;
    align-items: center;
  }
  .footer-text {
    font-size: 16px;
  }

  .logo-img {
    height: 70px;
  }
  .footer-buttons {
    width: 100%;
    flex-direction: column; /* Stack buttons vertically */
    align-items: center;
  }
  .footer-links {
    width: 100%; /* Adjusted width */
  }
  .footer-links p {
    margin: 10px 0; /* Adjusted margin */
  }
}
