.price_table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.price-table {
  border-collapse: collapse;
  width: 40%;
  margin-top: 100px;
}

.country-service,
.average-cost {
  width: 50%; /* Set a fixed width for each td */
}

.header-row,
.data-row {
  font-weight: 700;
}

.header-row {
  font-size: 28px;
  color: #a04167;
  border-bottom: 1px solid #27b3df;
}

.data-row {
  font-size: 20px;
  border-bottom: 1px solid #27b3df;
}

td {
  padding: 30px; /* Uniform padding for all td elements */
}

.average-cost {
  border-left: 1px solid #27b3df;
}

.logo-cell .logo {
  width: 70%; /* Adjusted width for the logo */
  max-width: 100px; /* Maximum width for the logo */
}

.final-cost .h1 {
  font-weight: bold;
  font-size: 43px;
}

.final-row {
  border-right: 1px solid #27b3df; /* Ensuring border for the last row */
}

.logo-cell .logo {
  width: 100%; /* Full width for the logo on medium screens */
  max-width: none; /* Removing the maximum width restriction */
}

.h1 {
  font-family: "CoreCircus", sans-serif;
  font-size: 4rem; /* Adjusted font size */
  text-align: center;
  margin: 0;
  color: rgba(45, 45, 45, 1);
  letter-spacing: -0.2rem;
  position: relative; /* Changed from absolute to relative */
  animation: flicker 3s linear infinite;
}

.h1:before {
  content: attr(data-text);
  position: absolute;
  overflow: hidden;
  color: #ffcc00;
  width: 100%;
  z-index: 5;
  text-shadow: none;
  left: 0; /* Adjusted left position */
  top: 0; /* Added top position */
  text-align: left;
}

.MO {
  font-weight: bold;
  font-size: 43px;
}

@keyframes flicker {
  0%,
  19.999%,
  22%,
  62.999%,
  64%,
  64.999%,
  70%,
  100% {
    opacity: 0.99;
    text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.4),
      1px -1px 0 rgba(255, 255, 255, 0.4), -1px 1px 0 rgba(255, 255, 255, 0.4),
      1px 1px 0 rgba(255, 255, 255, 0.4), 0 -2px 8px, 0 0 2px, 0 0 5px #ff7e00,
      0 0 15px #ff4444, 0 0 2px #ff7e00, 0 2px 3px #000;
  }
  20%,
  21.999%,
  63%,
  63.999%,
  65%,
  69.999% {
    opacity: 0.4;
    text-shadow: none;
  }
}

@font-face {
  font-family: "CoreCircus2DDot1";
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/333BF4_1_0.eot");
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/333BF4_1_0.eot?#iefix")
      format("embedded-opentype"),
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/333BF4_1_0.woff2")
      format("woff2"),
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/333BF4_1_0.woff")
      format("woff"),
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/333BF4_1_0.ttf")
      format("truetype");
}

@font-face {
  font-family: "CoreCircus";
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/333BF4_8_0.eot");
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/333BF4_8_0.eot?#iefix")
      format("embedded-opentype"),
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/333BF4_8_0.woff2")
      format("woff2"),
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/333BF4_8_0.woff")
      format("woff"),
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/333BF4_8_0.ttf")
      format("truetype");
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .price-table {
    width: 60%;
  }
  .logo-cell .logo {
    width: 100%; /* Full width for the logo on medium screens */
    max-width: none; /* Removing the maximum width restriction */
  }
}

@media (max-width: 768px) {
  .price-table {
    width: 80%;
    font-size: 16px;
  }

  td {
    padding: 20px; /* Adjusted padding for medium screens */
  }

  .logo-cell .logo {
    width: 100%; /* Full width for the logo on medium screens */
    max-width: none; /* Removing the maximum width restriction */
  }
}

@media (max-width: 425px) {
  .price-table {
    width: 95%;
    font-size: 12px; /* Reduced font size for smaller screens */
  }

  td {
    padding: 15px; /* Adjusted padding for smaller screens */
  }
  .logo-cell .logo {
    width: 80%; /* Full width for the logo on medium screens */
    max-width: none; /* Removing the maximum width restriction */
  }

  .final-cost .h1 {
    font-size: 26px; /* Reduced font size for cost in smaller screens */
  }

  .MO {
    font-size: 30px; /* Reduced font size for cost in smaller screens */
  }
}
